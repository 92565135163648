<template>
  <div
    class="fixed w-72 right-0 h-full bg-white rounded-l-2xl p-5 pt-0 overflow-y-auto overflow-x-hidden"
  >
    <div class="flex items-center my-5 gap-5">
      <div
        class="bg-red-500 bg-opacity-30 p-3 mt-5 rounded-full cursor-pointer"
        @click="$emit('close')"
      >
        <CloseIcon />
      </div>
      <h1 class="text-xl font-bold mt-5">Corpse Profile</h1>
    </div>
    <img :src="corpse.image" class="rounded-full w-5/6 my-10 mx-auto" />
    <div class="text-lg border-b pb-2 mb-5">
      <h3 class="text-sm text-gray-600 mb-2">Name of Deceased</h3>
      {{ corpse.name_of_deceased }}
    </div>
    <div class="text-lg border-b pb-2 mb-5">
      <h3 class="text-sm text-gray-600 mb-2">Age of Deceased</h3>
      {{ corpse.age_of_deceased }}
    </div>
    <div class="text-lg border-b pb-2 mb-5">
      <h3 class="text-sm text-gray-600 mb-2">Gender</h3>
      {{ corpse.gender_of_deceased }}
    </div>
    <div class="text-lg border-b pb-2 mb-5">
      <h3 class="text-sm text-gray-600 mb-2">Cause of Death</h3>
      {{ corpse.cause_of_death }}
    </div>
    <div class="text-lg border-b pb-2 mb-5">
      <h3 class="text-sm text-gray-600 mb-2">Date of Admission</h3>
      {{ corpse.date_of_admission }}
    </div>
    <div class="text-lg border-b pb-2 mb-5">
      <h3 class="text-sm text-gray-600 mb-2">Time of Admission</h3>
      {{ corpse.time_of_admission }}
    </div>
    <div class="text-lg border-b pb-2 mb-5">
      <h3 class="text-sm text-gray-600 mb-2">Admitted by</h3>
      {{ corpse.staff.firstname }} {{ corpse.staff.lastname }}
    </div>
    <button
      @click="$emit('edit', corpse)"
      class="w-full mt-5 mb-10 bg-primary text-white py-3 rounded-md"
    >
      Edit Corpse
    </button>
  </div>
</template>

<script>
export default {
  name: "EditCorpseModal",
  props: ["corpse"],
  components: {
    CloseIcon: () => import("@/assets/icons/CloseIcon.vue"),
  },
};
</script>
